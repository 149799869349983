import { useState, useEffect } from "react";

import Cookies from 'universal-cookie';

import Login from "templates/Login";
import Contents from "templates/Contents";

import { GetListPag, RegisterListPag, SetListPag } from "interface/Page";

import { typeCookie, typeCookieEmail, typeCookiePassw, typeCookiePageId } from "fixedData";

import { Access } from "services/AccessLogin";

import Preview_Budget from "components/Preview/Budget";
import Preview_Proposal from "components/Preview/Proposal";
import Preview_Portfolio from "components/Preview/Portfolio";
import Preview_IntegrationManual from "components/Preview/IntegrationManual";
import Preview_BudgetUpdate from "components/Preview/BudgetUpdate";

export default function App() {

    const cookies = new Cookies();
    const [ typePage, setTypePage ]       = useState(GetListPag('page'));
    const [ currentPage, setCurrentPage ] = useState(GetListPag('currentPage'));    

    const url = new URLSearchParams(window.location.search);
    const showLink = url.get("project");

    const [ userLoggedIn, setUserLoggedIn ] = useState(false);

    useEffect(()=>{
        RegisterListPag('page', setTypePage);
        RegisterListPag('currentPage', setCurrentPage);

        if(cookies.get(typeCookie)){
            let email = cookies.get(typeCookieEmail);
            let passw = cookies.get(typeCookiePassw);
            Access('return', email, passw, ()=>{ setUserLoggedIn(true); }, ()=>{ });
        }

        if(cookies.get(typeCookiePageId)){
            let idPage = cookies.get(typeCookiePageId);
            if(idPage == 'undefined'){
                SetListPag('currentPage', 'index');
                SetListPag('currentPageId', 'remuve');
            }else {
                SetListPag('currentPageId', idPage);
            }
        }
    }, []);

    // checked page
    function CheckedPage(){
        switch(typePage){
            case "dashboard":
                if(showLink){
                    return OpenPreview('dashboard');
                }else {
                    return <Contents />;
                }

            default:
                if(showLink){
                    return OpenPreview('login');
                }else {
                    return <Login />;
                }
        }
    }

    function OpenPreview(value){
        if(showLink){
            let typeLink = showLink.split('/');            
            switch (typeLink[0]) {
                case 'portfolio':
                    return <Preview_Portfolio typeAccess={ value } url={ typeLink[1] } />;

                case 'proposal':
                    return <Preview_Proposal typeAccess={ value } idPage={ typeLink[1] } />;

                case 'budget':
                    return <Preview_Budget typeAccess={ value } userLoggedIn={ userLoggedIn } idPage={ typeLink[1] } />;

                case 'budget_update':
                    return <Preview_BudgetUpdate typeAccess={ value } idPage={ typeLink[1] } />;

                case 'integration_manual':
                    return <Preview_IntegrationManual typeAccess={ value } idPage={ typeLink[1] } />;
            
                default:
                    return <Login />;
            }
        }
    }

    return CheckedPage();
}