import { useState, useEffect } from "react";

import './Budget.css';

import { GetDataPage } from "interface/Data";
import { SetModalData, SetModalState } from "interface/PopUp";

import { Reg_EmailPreview } from "services/RegisterData";

import { SvgCheked, SvgCheked_1, SvgDownload, SvgLogoTipo, SvgSetaUp } from "components/SvgFile";

export default function Preview_Budget(props){
    
    const [ loading, setLoading ] = useState(false);
    const [ showData, setShowData ] = useState(InitialData('all'));
    const [ typeAccess, setTypeAccess ] = useState(props.userLoggedIn);
    const [ infSaved, setInfSaved ] = useState(false);
    const [ showPageClient, setShowPageClient ] = useState(true);

    const [ email, setEmail ] = useState(InitialData('emailContact'));
    const [ idClient, setIdClient ] = useState(InitialData('idClient'));
    const [ contact, setContact ] = useState(InitialData('contact'));

    function InitialData(type){
        const newData = GetDataPage('budget').find(item => item.id == props.idPage);
        if(newData){
            if(type == 'all'){
                return newData;
            }else {
                return newData[type];
            }            
        }
        if(type == 'all'){
            return false;
        }else {
            return '';
        }
    }

    function ShowDateBudget(dateStart, dateEnd){
        if(dateEnd == '30/11/-0001'){
            return dateStart;
        }else {
            let newDate = dateStart.split('/');
            return newDate[0] + "/" + newDate[1] + " - " + dateEnd;
        }
    }

    function ShowCashTotal(type){
        let cashTotal = 0;
        showData.services.map((elem, index)=>{
            cashTotal += elem.list_services[0].cash_total_taxa;
        });

        if(type == 'total'){
            return cashTotal.toLocaleString('pt-br', { minimumFractionDigits: 2 })

        }else if(type == 'discount'){
            
            let altDiscount = '';
            if(showData.discount){
                altDiscount = showData.discount.replaceAll('.', '');
                altDiscount = showData.discount.replaceAll(',', '.');
            }

            if(showData.type_discount == 'percentage'){
                let countDiscount     = cashTotal * (altDiscount / 100);
                let cashTotalDiscount = cashTotal - countDiscount;
                return "R$ " + cashTotalDiscount.toLocaleString('pt-br', { minimumFractionDigits: 2 });

            }else if(showData.type_discount == 'cash'){
                return "R$ " + (cashTotal - altDiscount).toLocaleString('pt-br', { minimumFractionDigits: 2 });
            }

            return cashTotal.toLocaleString('pt-br', { minimumFractionDigits: 2 })
        }
    }

    function ReturnIndex(){
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }
    
    function SaveData(event){
        event.preventDefault();
        setInfSaved(true);
        Reg_EmailPreview(props.idPage, idClient, contact, email, ()=>{ CallbackSuccess() }, ()=>{ setLoading(false) });
    }
    
    function CallbackSuccess(){
        SetModalData('ReturnResponse', { "page": "ok" });
        SetModalState('ReturnResponse', true);
    }

    useEffect(()=>{
        document.title = showData == false ? "Erro ao abrir informações" : showData.subject;
        document.body.style.backgroundColor = '#e5e5e5';        
        
        window.addEventListener("scroll", ()=>{
            if(window.scrollY >= 60){
                document.getElementById('return').classList.add('return_index');
                document.getElementById('return').classList.remove('return_close');
            }else {
                document.getElementById('return').classList.add('return_close');
                document.getElementById('return').classList.remove('return_index');
            }
        });
    }, []);

    useEffect(()=>{
        setTypeAccess(props.userLoggedIn);
    }, [props.userLoggedIn]); 

    return(
        <div className="Preview_Budget">
            <div className={ loading == true ? "return_data_save div_loading" : "return_data_save" }>
                <img alt="loading" src="./assets/loading.gif" className="loading" />
            </div>
            
            {
                showData == false ?
                    <div className="no_data_budget">
                        <div className="inf_show_budget">
                            Erro ao abrir orçamento, contate nossa equipe para corrigir!
                        </div>
                    </div>
                :
                    <>
                        {
                            typeAccess ? 
                                <div className="show_type_page" onClick={ ()=>{ setShowPageClient(!showPageClient) } }>
                                    {
                                        showPageClient ?
                                        "Visualizar página como cliente"
                                        :
                                        "Visualizar página como administrador"
                                    }
                                </div>
                            : null
                        }
                        {                            
                            typeAccess && showPageClient ?
                            <div className="inf_budget">
                                <div className="div_line">
                                    <div className="line_ line_0" />
                                    <div className="line_ line_1" />
                                    <div className="line_ line_2" />
                                </div>

                                <div className="container">
                                    <div className="div_download">
                                        <a href={ process.env.REACT_APP_API_URL + 'php/generatePdf.php?acao=budget/' + props.idPage } target="_blank">
                                            <div className="download">
                                                <SvgDownload className="img_download" color="#3d3d3d" />
                                            </div>
                                        </a>
                                    </div>
                                    
                                    <div className="div_topic">
                                        <div className="div_logo">
                                            <div className="div_height_logo">
                                                <SvgLogoTipo className="logotipo" color="#2D3C53" />
                                            </div>
                                        </div>
                                        <div className="div_data_2">
                                            <div className="div_height_title title_1">Orçamento</div>
                                            <div className="subtitle_1_name">#EST-{ showData.code }</div>
                                        </div>
                                        <div className="div_data_2">
                                            <div className="div_name">
                                                <div className="subtitle_1">Chroma Garden</div>
                                                <div className="">CNPJ 20.817.641/0001-40</div>
                                                <div className="">Av. Iguaçu, 2947 - 17º andar - Batel,</div>
                                                <div className="">Curitiba - PR, 80240-031, Brazil</div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="div_data_budget">
                                        <div className="">
                                            <table cellPadding="0" cellSpacing="0">
                                                <tbody>
                                                    <tr>
                                                        <td className="subtitle_1" align="left">Empresa:</td>
                                                    </tr>
                                                    <tr>
                                                        <td align="left">{ showData.nameClient }</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="subtitle_1" align="left">Assunto:</td>
                                                    </tr>
                                                    <tr>
                                                        <td align="left">{ showData.subject }</td>
                                                    </tr>
                                                    {
                                                        showData.nameProject ? 
                                                        <>
                                                            <tr>
                                                                <td className="subtitle_1" align="left">Projeto:</td>
                                                            </tr>
                                                            <tr>
                                                                <td align="left">{ showData.nameProject }</td>
                                                            </tr>
                                                        </> : null
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                        <div className="">
                                            <table cellPadding="0" cellSpacing="0">
                                                <tbody>
                                                    {
                                                        showData.dateStartBr == "30/11/-0001" || showData.dateEndBr == "30/11/-0001" ? null :
                                                        <>
                                                            <tr>
                                                                <td className="subtitle_1" align="left">Data do evento:</td>
                                                            </tr>
                                                            <tr>
                                                                <td align="left">{ ShowDateBudget(showData.dateStartBr, showData.dateEndBr) }</td>
                                                            </tr>
                                                        </> 
                                                    }
                                                    {
                                                        showData.dueDateBr == "30/11/-0001" ? null : 
                                                        <>
                                                            <tr>
                                                                <td className="subtitle_1" align="left">Data de vencimento:</td>
                                                            </tr>
                                                            <tr>
                                                                <td align="left">{ showData.dueDateBr }</td>
                                                            </tr>
                                                        </>
                                                    }
                                                </tbody>
                                            </table> 
                                        </div>
                                    </div>

                                    <div className="div_show_contents">
                                        {
                                            showData.services.map((key, index)=>{
                                                return(
                                                    <div className="show_budget_desktop" key={ index }>
                                                        <table className="" cellPadding="0" cellSpacing="0">
                                                            {
                                                                key.title == '' ? null :
                                                                <thead>
                                                                    <tr style={ { backgroundColor: '#000000', color: "#ffffff" }}>
                                                                        <td className="" colSpan="6">
                                                                            { key.title }
                                                                        </td>
                                                                    </tr>
                                                                </thead>
                                                            }
                                                            <thead>
                                                                <tr>
                                                                    <th width="20" align="center">N°</th>
                                                                    <th className="th_table">Item & Descrição</th>
                                                                    <th width="40" align="center">Qtd</th>
                                                                    <th width="80">Valor Unit.</th>
                                                                    <th width="40" align="center">Imposto</th>
                                                                    <th width="140">Valor com imposto</th>
                                                                </tr>
                                                            </thead>
                                                            {
                                                                key.list_services.map((key_1, index_1)=>{
                                                                    return(
                                                                        <tbody key={ index_1 }>
                                                                            {
                                                                                key_1.service.map((key_2, index_2)=>{
                                                                                    return(
                                                                                        <tr key={ index_2 }>
                                                                                            <td className="" align="center">{ index_2 + 1 }</td>
                                                                                            <td className="">
                                                                                                <div className="service_title">{ key_2.title }</div>
                                                                                                <div className="service_text" dangerouslySetInnerHTML={ { __html: key_2.text ? key_2.text.replaceAll('&#34;', '"').replaceAll('<li><br></li>', '') : "" } } />
                                                                                            </td>
                                                                                            <td className="" align="center">{ key_2.qtd }</td>
                                                                                            {
                                                                                                key_2.cash == '0,00' ? 
                                                                                                <td className="" align="center">---</td> :
                                                                                                <td className="" align="right">{ key_2.cash }</td>
                                                                                            }
                                                                                            <td className="" align="center">{ key_2.tax }%</td>
                                                                                            <td className="td_right" align="center">{ key_2.cash_total_tax }</td>
                                                                                        </tr>
                                                                                    )
                                                                                })
                                                                            }
                                                                            <tr>
                                                                                <td className="subtitle_1" align="right" colSpan="5">Subtotal (sem imposto)</td>
                                                                                <td className="td_right" align="right">
                                                                                    {
                                                                                        key_1.cash_total.toLocaleString('pt-br', {minimumFractionDigits: 2})
                                                                                    }
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td className="subtitle_1" align="right" colSpan="5">
                                                                                    Imposto sobre serviços
                                                                                </td>
                                                                                <td className="td_right" align="right">
                                                                                    { key_1.total_taxa.toLocaleString('pt-br', {minimumFractionDigits: 2}) }
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td className="subtitle_1" align="right" colSpan="5">Total (com imposto)</td>
                                                                                <td className="td_right" align="right">
                                                                                    {
                                                                                        key_1.cash_total_taxa.toLocaleString('pt-br', {minimumFractionDigits: 2})
                                                                                    }
                                                                                </td>
                                                                            </tr>
                                                                        </tbody>
                                                                    )
                                                                })
                                                            }
                                                        </table>
                                                    </div>
                                                )
                                            })
                                        }
                                        {
                                            showData.services.map((key, index)=>{
                                                return(
                                                    <div className="show_budget_mobile" key={ index }>
                                                        <table className="" cellPadding="0" cellSpacing="0">
                                                            <thead>
                                                                <tr style={ { backgroundColor: '#000000', color: "#ffffff" }}>
                                                                    <td className="" colSpan="5">
                                                                        { key.title }
                                                                    </td>
                                                                </tr>
                                                            </thead>
                                                            <thead>
                                                                <tr>
                                                                    <th width="20" align="center">N°</th>
                                                                    <th style={ { width: "100%" } } colSpan="2">Item & Descrição</th>
                                                                </tr>
                                                            </thead>
                                                            {
                                                                key.list_services.map((key_1, index_1)=>{
                                                                    return(
                                                                        <tbody key={ index_1 }>
                                                                            {
                                                                                key_1.service.map((key_2, index_2)=>{
                                                                                    return(
                                                                                        <tr key={ index_2 }>
                                                                                            <td className="" align="center">{ index_2 + 1 }</td>
                                                                                            <td className="td_right" colSpan="2">
                                                                                                <div className="service_title">
                                                                                                    { key_2.title }
                                                                                                </div>
                                                                                                <div className="service_text" dangerouslySetInnerHTML={ { __html: key_2.text ? key_2.text.replaceAll('&#34;', '"').replaceAll('<li><br></li>', '') : "" } } />
                                                                                                <table cellPadding="0" cellSpacing="0" style={ { marginTop: "16px", marginBottom: 0 } }>
                                                                                                    <tbody>
                                                                                                        <tr>                   
                                                                                                            <th className="th_mobile border_mobile_left" width="40" align="center">Imposto = { key_2.tax }%</th>
                                                                                                            <th className="th_mobile" width="40" align="center">Quant. = { key_2.qtd }</th>
                                                                                                            <th className="th_mobile border_mobile_right" width="80">Montante = { key_2.cash_total == '0,00' ? "---" : key_2.cash_total }</th> 
                                                                                                        </tr>
                                                                                                    </tbody>
                                                                                                </table>
                                                                                            </td>
                                                                                        </tr>
                                                                                    )
                                                                                })
                                                                            }
                                                                            <tr>
                                                                                <td className="subtitle_1" align="right" colSpan="2">Subtotal (sem imposto)</td>
                                                                                <td className="td_right" align="right">
                                                                                    {
                                                                                        key_1.cash_total.toLocaleString('pt-br', {minimumFractionDigits: 2})
                                                                                    }
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td className="subtitle_1" align="right" colSpan="2">
                                                                                    Imposto sobre serviços
                                                                                </td>
                                                                                <td className="td_right" align="right">
                                                                                    { key_1.total_taxa.toLocaleString('pt-br', {minimumFractionDigits: 2}) }
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td className="subtitle_1" align="right" colSpan="2">Total (com imposto)</td>
                                                                                <td className="td_right" align="right">
                                                                                    {
                                                                                        key_1.cash_total_taxa.toLocaleString('pt-br', {minimumFractionDigits: 2})
                                                                                    }
                                                                                </td>
                                                                            </tr>
                                                                        </tbody>
                                                                    )
                                                                })
                                                            }
                                                        </table>
                                                    </div>
                                                )
                                            })
                                        }

                                        {
                                            showData.show_group ? 
                                            <div className="">
                                                <table className="" cellPadding="0" cellSpacing="0">
                                                    <thead>
                                                        <tr style={ { backgroundColor: '#000000', color: "#ffffff" }}>
                                                            <td className="" colSpan="3">Resumo do orçamento</td>
                                                        </tr>
                                                    </thead>
                                                    <thead>
                                                        <tr>
                                                            <th width="20" align="center">N°</th>
                                                            <th>Título</th>
                                                            <th width="140">Valor com imposto</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            showData.services.map((elem, index)=>{
                                                                return(
                                                                    <tr key={ index }>
                                                                        <td className="" align="center">{ index + 1 }</td>
                                                                        <td className="" align="left">{ elem.title }</td>
                                                                        <td className="td_right" align="center">
                                                                            {
                                                                                elem.list_services[0].cash_total_taxa.toLocaleString('pt-br', { minimumFractionDigits: 2 })
                                                                            } 
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            })
                                                        }
                                                        <tr>
                                                            <td className="subtitle_1" colSpan={ 2 } align="right">Total</td>
                                                            <td className="td_right" align="center">
                                                                {
                                                                    ShowCashTotal('total')
                                                                }
                                                            </td>
                                                        </tr>
                                                        {
                                                            showData.type_discount != 'not' ? 
                                                            <>
                                                                <tr>
                                                                    <td className="subtitle_1" colSpan={ 2 } align="right"><b>Desconto</b></td>
                                                                    <td className="td_right" align="center">
                                                                        {
                                                                            showData.type_discount == "percentage" ? showData.discount + "%" : "R$ " + showData.discount
                                                                        }
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td className="subtitle_1" colSpan={ 2 } align="right"><b>Total com desconto</b></td>
                                                                    <td className="td_right" align="center">
                                                                        {
                                                                            ShowCashTotal('discount')
                                                                        } 
                                                                    </td>
                                                                </tr>
                                                            </>
                                                            : null
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>
                                            : null
                                        }
                                    </div>

                                    {
                                        showData.text_fixed.length > 0 ? 
                                        <div className="div_text_fixed">
                                            {
                                                showData.text_fixed.map((key, index)=>{
                                                    return(
                                                        <div className="" key={ index }>
                                                            <div className="subtitle_1">{ key.title }</div>
                                                            <div className="" dangerouslySetInnerHTML={ { __html: key.text ? key.text.replaceAll('&#34;', '"').replaceAll('<li><br></li>', '') : "" } } />
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div> : null
                                    }
                    
                                    <div className="div_signature">
                                        <div className="show_signature">
                                            <div className="div_img">
                                                <img alt="img" src={ "./assets/signature/" + showData.signatureImg } className="img" />
                                            </div>
                                            <div className="">{ showData.nameSignature }</div>
                                            <div className="subtitle_1">Assinatura Autorizada</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            :
                            <div className="inf_budget page_client">
                                <div className="div_email">
                                    <div className="div_input">
                                        <div className="text_">
                                            Você vai entrar em um<br />
                                            jardim fértil de ideias,<br />
                                            onde a tecnologia conta histórias 
                                        </div>
                                        <form className="input" onSubmit={ SaveData }>
                                            <input type="email" className="" placeholder="teste@teste.com" value={ email } onChange={ (e)=>{ setEmail(e.target.value) } } required />
                                            {
                                                infSaved ?
                                                <div className="register saved_email">
                                                    <SvgCheked_1 className="icons" color="#000000" />
                                                </div>
                                                :
                                                <button className="register">
                                                    Quero receber a<br />
                                                    news letter Chroma
                                                </button>
                                            }
                                        </form>
                                    </div>
                                    <a href={ process.env.REACT_APP_API_URL + 'php/generatePdf.php?acao=budget/' + props.idPage } target="_blank" className="download_div">
                                        <div className="download_text">
                                            Faça o download do seu<br />
                                            orçamento aqui
                                        </div>
                                        <div className="download_line"></div>
                                        <div className="download_img">
                                            <img alt="img" src="./assets/bg_icon_01.png" className="img_" />
                                        </div>
                                    </a>
                                </div>

                                <div className="img_bg" style={ { backgroundImage: "url('./assets/bg_budget.png')" } }>
                                    <div className="bg_logo">
                                        <img alt="img" src="./assets/bg_icons.png" className="img_" />
                                    </div>
                                    <div className="list_icons">
                                        <div className="div_icons">
                                            <a href="https://www.behance.net/chromagarden" target="_blank" className="rede_social">
                                                <img alt="img" src="./assets/bg_behance.png" className="img_" />
                                            </a>
                                            <a href="https://www.instagram.com/chromagarden/?utm_source=ig_web_button_share_sheet&igshid=OGQ5ZDc2ODk2ZA%3D%3D" target="_blank" className="rede_social">
                                                <img alt="img" src="./assets/bg_icon_02.png" className="img_" />
                                            </a>
                                            <a href="https://www.linkedin.com/company/chroma-garden/posts/?feedView=all" target="_blank" className="rede_social">
                                                <img alt="img" src="./assets/bg_icon_05.png" className="img_" />
                                            </a>
                                            <a href="https://www.youtube.com/@chromagarden" target="_blank" className="rede_social">
                                                <img alt="img" src="./assets/bg_icon_04.png" className="img_" />
                                            </a>
                                            <a href="https://chromagarden.com/" target="_blank" className="rede_social">
                                                <img alt="img" src="./assets/bg_icon_03.png" className="img_" />
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                    </>
            }

            <div className="return_close" id="return" onClick={ ()=>{ ReturnIndex() } }>
                <SvgSetaUp className="icon_return" color="rgb(255 181 0)" />
            </div>

            {/*     
            <div className={ loading == true ? "return_data_save div_loading" : "return_data_save" }>
                <img alt="loading" src="./assets/loading.gif" className="loading" />
            </div>
            */}
        </div>
    )
}